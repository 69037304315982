<template>
	<div class="forgotPassword" ref="forgotPassword">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md5>
						<v-card class="elevation-12">
							<v-toolbar dark flat class="" color="primary">
								<v-toolbar-title>
									<span v-if="!isSent">Войдите через смс</span>
									<span v-else-if="!enterPassword">Войдите через смс</span>
									<span v-else>Создание нового пароля</span>
								</v-toolbar-title>
							</v-toolbar>
							<v-card-text class="px-6 pb-0">
								<v-alert
									v-if="alert"
									dense
									text
									:type="alert.type || 'warning'"
								>
									{{ alert.message || $t("login.unexpectedError") }}
								</v-alert>

								<span class="subtitle-2 grey-accent-4--text font-weight-light">
									<span v-if="!isSent">
										Пароль придет на указаный номер по смс
									</span>
									<span v-else-if="!enterPassword"
										>На номер {{ phone }} отправлен СМС с кодом</span
									>
								</span>
								<v-form ref="form" v-model="valid" class="mt-2" lazy-validation>
									<v-row v-if="!isSent && !enterPassword">
										<v-col cols="6" md="5" xl="3" lg="4" class="mt-2">
											<CountryCode 
												@change-mask="changeMask($event)"
											/>
										</v-col>
										<v-col cols="6" md="7" xl="9" lg="8" class="d-flex align-center py-0 pl-0 pt-2">
											<v-text-field
												ref="phone"
												v-model="phone"
												:placeholder="selected.code"
												persistent-placeholder
												v-mask="selected.mask"
												name="phone"
												prepend-icon="mdi-account"
												type="text"
												:disabled="loading"
												:label="$t('login.phoneNumber')"
												:rules="phoneRules"
												required
											></v-text-field>
										</v-col>
									</v-row>
									<!-- Номер -->
									<!-- Код -->
									<v-text-field
										v-else-if="isSent && !enterPassword"
										v-model="code"
										persistent-placeholder
										prepend-icon="mdi-email"
										type="text"
										label="Введите код из СМС"
										required
									></v-text-field>
									<!-- Пароль -->
									<v-text-field
										v-if="enterPassword"
										v-model="newPassword"
										label="Новый пароль"
										persistent-placeholder
										type="password"
										:disabled="loading"
										required
									></v-text-field>
									<v-text-field
										v-if="enterPassword"
										v-model="confirmPassword"
										label="Подтвердите пароль"
										persistent-placeholder
										type="password"
										:rules="[
											v =>
												!v.length || v === newPassword || 'Пароли не совпадают',
										]"
										:disabled="loading"
										required
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions class="pa-6 pt-3">
								<v-btn
									v-if="!isSent"
									:disabled="!valid"
									class="px-4 subtitle-2 text-capitalize"
									color="primary"
									depressed
									:loading="loading"
									@click="generatePassword"
								>
									Отправить СМС
								</v-btn>
								<v-btn
									v-else-if="!enterPassword"
									class="px-4 subtitle-2 text-capitalize"
									color="primary"
									depressed
									:loading="loading"
									:disabled="!code.length"
									@click="sendCode"
								>
									Отправить
								</v-btn>
								<v-btn
									v-else
									class="px-4 subtitle-2 text-capitalize"
									color="primary"
									depressed
									:loading="loading"
									:disabled="disabledSavePasswordBtn"
									@click="savePassword"
								>
									Сохранить
								</v-btn>

								<v-spacer></v-spacer>
								<div class="d-flex flex-column">
									<router-link
										class="href mb-1 subtitle-2 font-weight-regular primary--text"
										to="/login"
									>
										Вернуться
									</router-link>
									<!-- <router-link
										class="href subtitle-2 font-weight-regular"
										to="/register"
									>
										{{ $t("login.register") }}
									</router-link> -->
								</div>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import CountryCode from "@/components/app/SelectCountryCode.vue";
export default {
	name: "Login",
	data() {
		return {
			alert: null,
			code: "",
			loading: false,
			isSent: false,
			selected: {
				code: '',
				mask: ''
			},
			confirmPassword: "",
			newPassword: "",
			enterPassword: false,
			valid: false,
			phone: this.$route.query.phone || "",
			phoneRules: [
				v => !!v || this.$t("login.errors.enterPhone"),
				v => this.selected.code === '+7' ? ((v && v.length === 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
			],
		};
	},

	computed: {
		disabledSavePasswordBtn() {
			return (
				this.newPassword === "" || this.confirmPassword !== this.newPassword
			);
		},
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => (this.alert = null), 2500);
		},
	},
	methods: {
		changeMask(event) {
			this.$nextTick(() => {
				this.phone = '';
			});
			this.selected = event
		},
		async generatePassword() {
			this.validate();

			try {
				this.loading = true;

				const res = await this.$api.user.generate_password({
					phone: this.phone,
				});

				this.isSent = true;

				this.alert = { type: "success", message: "СМС-код успешно отправлен" };
			} catch (error) {
				if (error.message.includes("phone")) {
					error.message = "Проверьте правильность введенных Вами данных";
				}
				this.alert = { type: "error", message: error.message };
			} finally {
				this.loading = false;
			}
		},

		async sendCode() {
			try {
				this.loading = true;

				const data = {
					phone: this.phone,
					password: this.code,
					application: "company"
				};

				const res = await this.$store.dispatch("auth/login", data);

				console.log(res);
				this.$router.push("/");
				// this.enterPassword = true;
			} catch (error) {
				this.alert = {
					type: "error",
					message: error.message.response.data.message || "Проверьте правильность введенного вами кода",
				};
			} finally {
				this.loading = false;
			}
		},

		async savePassword() {
			try {
				this.loading = true;
				let res = await this.$api.user.change_password({
					phone: this.phone,
					auth_password: this.newPassword,
				});

				this.alert = { type: "success", message: res.success };

				const data = {
					phone: this.phone,
					password: this.newPassword,
				};

				res = await this.$store.dispatch("auth/login", data);

				this.$router.push("/");
			} catch (error) {
				this.alert = { type: "error", message: error.message };
			} finally {
				this.loading = false;
			}
		},

		validate() {
			this.$refs.form.validate();
		},
	},
	components: {
		CountryCode
	}
};
</script>

<style lang="scss" scoped>
.forgotPassword {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
}
</style>
